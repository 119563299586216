<template>
  <page-content>

    <common-table
      ref="table"
      path="subUserRecord"
      :columns="columns">

      <template slot="search">
        <a-button  ghost type="primary" @click="add">新增</a-button>
      </template>

      <template slot="status" slot-scope="{record}">
        <a-dropdown :trigger="['click']" v-if="record.status==0">
          <a-menu slot="overlay" >
            <a-menu-item key="2" @click="handleSetStatus(record,1)">同意</a-menu-item>
            <a-menu-item key="3" @click="handleSetStatus(record,2)">拒绝</a-menu-item>
          </a-menu>
          <a-button type="danger" size="small">待处理 <a-icon type="down"></a-icon></a-button>
        </a-dropdown>
        <a-tag v-else-if="record.status==1">已同意</a-tag>
        <a-tag v-else>已拒绝</a-tag>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit  @click="edit(record)"></action-edit>
        <action-delete  @click="remove(record)"></action-delete>
      </template>

    </common-table>

  </page-content>
</template>
<script>
  export default {
    components: {
    },
    data () {
      return {

      }
    },

    computed: {
      columns () {
        return [
          {
            title: '申请人',
            dataIndex: 'name',
          },
          {
            title: '客户姓名',
            dataIndex: 'targetName'
          },
          {
            title: '客户手机',
            dataIndex: 'targetPhone'
          },

          {
            title: '添加时间',
            dataIndex: 'createdAt'
          },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' }
          },
          {
            title: '操作',
            dataIndex: 'operation',
            width:120,
            scopedSlots: { customRender: 'operation' }
          }
        ]
      }
    },
    methods: {
      add () {
        this.$refs.newsEdit.show()
      },
      edit (record) {
        this.$refs.newsEdit.show(record)
      },
      remove (record) {
        var that = this
        this.$confirm({
          title: '是否删除该备案记录?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('subUserRecord/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      viewComment (record){
        var newsId = record.id
        this.$router.push(`/app/news/${newsId}/comment`)
      },

      getList () {
        this.$refs.table.getData()
      },

      handleSetStatus(record,status){
        this.$put("/subUserRecord/"+record.id+"/status",{status}).then(suc=>{
          this.$refs.table.getData()
        })
      }

    }
  }
</script>
<style lang="less" scoped>

  .news-logo {
    width: 60px;
    height: 60px;
  }
</style>
